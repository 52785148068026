import React, { Component } from "react";
import axios from "../../../axios"
import classes from "./overview.css"
import ProgressBar from "./ProgressBar/ProgressBar"
import ContentContainer from "../../../containers/UI/ContentContainer/ContentContainer"


class Financial extends Component {

  state = {

    orderAmount: 0,
    invoiceAmount: 0,
    productMargin: 0,
    projectMargin: 0,

    // Adapt each year, possibly dynamically 
    totalTarget: 120000,
    totalRange: 240000,
    productTarget: 60000,
    projectTarget: 60000,
  }


  componentDidMount() {

    // Fetch financial overview
    axios.get("/orders").then(response => {
      if (response.data) {

        let orders = response.data
        let year = new Date().getFullYear().toString()
        let orderAmount = 0
        let invoiceAmount = 0
        let productMargin = 0
        let projectMargin = 0

        // Loop over all orders for calculation
        orders.forEach(order => {
          if (order.financialYear === year) {
            let margin = order.totalAmount - order.cogsAmount

            // Assign margin to correct category
            if (order.category === "product") {
              productMargin += margin
            } else if (order.category === "project") {
              projectMargin += margin
            }

            // Assign to correct state
            if (order.invoiced === true) {
              invoiceAmount += margin
            } else {
              orderAmount += margin
            }
          }
        })

        // Update interface
        this.setState({ orderAmount, invoiceAmount, productMargin, projectMargin })
      }
    })
  }

  render() {

    return (
      <ContentContainer title="Overview">

        <p className={classes.subtitle}>Margin overview</p>

        <div className={classes.highlightContainer}>
          <div className={classes.highlightTile}>
            <label>{this.state.orderAmount ? "€ " + Math.round(this.state.orderAmount).toLocaleString("de-DE") : ""}</label>
            <label>On order {new Date().getFullYear()}</label>
          </div>
          <div className={classes.highlightTile}>
            <label>{this.state.invoiceAmount ? "€ " + Math.round(this.state.invoiceAmount).toLocaleString("de-DE") : ""}</label>
            <label>Invoiced {new Date().getFullYear()}</label>
          </div>
          <div className={classes.highlightTile}>
            <label>{this.state.invoiceAmount ? "€ " + Math.round(this.state.orderAmount + this.state.invoiceAmount).toLocaleString("de-DE") : ""}</label>
            <label>Total margin {new Date().getFullYear()}</label>
          </div>
        </div>

        <p className={classes.subtitle} style={{ marginTop: "70px" }}>Target progress</p>
        
        <div className={classes.progressContainer}>
          <ProgressBar
            title={"Total margin"}
            target={this.state.totalTarget}
            range={this.state.totalRange}
            value={this.state.projectMargin + this.state.productMargin}
            markers={6}
            large
            showPercentage
          />
          <ProgressBar
            title={"Product margin"}
            target={this.state.productTarget}
            range={this.state.productTarget}
            value={this.state.productMargin}
            markers={6}
            showAmount
          />
          <ProgressBar
            title={"Project margin"}
            target={this.state.projectTarget}
            range={this.state.projectTarget}
            value={this.state.projectMargin}
            markers={6}
            showAmount
          />
        </div>
      </ContentContainer>
    )
  }
}

export default Financial;
