import React, { Component } from "react";
import DataTable from "react-data-table-component";
import ContentContainer from "../../../containers/UI/ContentContainer/ContentContainer";
import axios from "../../../axios";
import classes from "./orders.css"
import InvoiceModal from "./OrderModal/OrderModal"

const columns = [
  { name: "Date", selector: "date", sortable: true, left: true, format: row => row.date ? (new Date(row.date * 1000).toLocaleDateString("en-GB")) : "", style: { fontSize: "14px" }},
  { name: "Customer", selector: row => (typeof(row.customer) === "object") ? row.customer.name : "", sortable: true, left: true, style: { fontSize: "14px" }},
  { name: "Reference", selector: row => row.internalReference, sortable: true, left: true, style: { fontSize: "14px" }},
  { name: "Total amount", selector: row => (row.totalAmount ? "€ " + parseFloat(row.totalAmount).toLocaleString("de-DE") : ""), sortable: true, left: true, style: { fontSize: "14px" }},
  { name: "Category", selector: row => row.category, format: row => capitalizeFirstLetter(row.category), sortable: true, left: true, style: { fontSize: "14px" }},
  { name: "Status", selector: row => getStateForOrder(row), sortable: true, right: true, cell: row => {
    let state = getStateForOrder(row)
    let color = getColorForState(state)
    return <div className={classes.statusIndicator} style={{backgroundColor: color}}>{state}</div>
  }},
]

class Orders extends Component {

  state = {
    orders: [],
    selectedOrder: null,
    showModal: false,
    customersOptions: [],
  }


  componentDidMount() {

    // Fetch all invoices
    this.refreshOrders()

    // Customers
    axios.get("/customers/allReduced").then(response => {
      if (response.data) {
        // Map into Select component
        const select = response.data.map((el) => {
          return { label: el.name, value: el._id }
        })
        this.setState({ customersOptions: select})
      }
    })
  }

  displayModal = (shouldBeShown, order) => {
    this.setState({ showModal: shouldBeShown, selectedOrder: order })

    // Update list after update
    if (shouldBeShown === false) {
      setTimeout(this.refreshOrders, 1000)
    }
  }

  refreshOrders = () => {
    axios.get("/orders").then(response => {
      if (response.data) {
        this.setState({ orders: response.data })
      }
    })
  }

  render() {
    return (
      <ContentContainer title="Bestellingen">
        <button onClick={() => this.displayModal(true, null)} className={classes.addButton}>Upload PO</button>

        <DataTable
          columns={columns}
          data={this.state.orders}
          noHeader={true}
          pagination={true}
          highlightOnHover={true}
          striped={true}
          defaultSortField={"date"}
          defaultSortAsc={false}
          customStyles={{ headCells: { style: { fontWeight: "bold", fontSize: "15px" }}}}
          onRowClicked={(order) => this.displayModal(true, order)}
        />

        {this.state.showModal && <>
          <InvoiceModal
            customersOptions={this.state.customersOptions}
            shouldShow={this.state.showModal}
            selected={this.state.selectedOrder}
            closeModal={() => this.displayModal(false, null)}/>
          </>
        }

      </ContentContainer>
    );
  }
}


// MARK: - Helpers

function getStateForOrder(order) {

  if (order.fulfilled !== true) {
    return "To be fulfilled"
  } else if (order.invoiced !== true) {
    return "To be invoiced"
  } else {
    return "Invoiced"
  }
}

function getColorForState(state) {
  switch (state) {
    case "Invoiced": return "#27ae60"
    case "To be invoiced": return "#e74c3c"
    case "To be fulfilled": return "#95a5a6"
    default: return ""
  }
}

function capitalizeFirstLetter(val) {
  if (val) {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1)
  } else {
    return null
  }
}

export default Orders
