import * as actionTypes from "./actionTypes";


// Publicly accessible actionCreators

// Only update locally
export const updatePrivileges = (privilegeList) => {
  return (dispatch) => { 
    dispatch({ 
      type: actionTypes.PRIVILEGE_CHANGE, 
      hasAdministrationPrivilege: privilegeList.hasAdministrationPrivilege,
      hasSalesPrivilege: privilegeList.hasSalesPrivilege, 
      hasStockPrivilege: privilegeList.hasStockPrivilege, 
      hasDeploymentPrivilege: privilegeList.hasDeploymentPrivilege,
      hasInfrastructurePrivilege: privilegeList.hasInfrastructurePrivilege, 
    })
  }
}
