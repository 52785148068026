import React from "react"
import classes from "./ProgressBar.css"


const bar = (props) => {

  const outerClasses = [classes.rowContainer];
  if (props.firstRow === true) {
    outerClasses.push(classes.isFirstRow);
  }

  let count = props.markers ?? 0
  let markers = []
  let axisLines = []
  let interval = props.range / count

  for (let i = 0; i < (count + 1); i++) {

    let amount = (interval * i)
    let className = amount === props.target ? classes.highlight : ""

    if (i !== 0) {
      markers.push(<div className={className}>€ {interval * i / 1000}K</div>)
    } else {
      markers.push(<div style={{width: "35px"}}></div>)
    }

    if (i !== 0 && i !== count) {
      axisLines.push(<div className={classes.line}></div>)
    } else {
      axisLines.push(<div></div>)
    }
  }

  return (
    <div className={classes.container}>
      <label className={classes.title} style={{ fontSize: props.large ? "18px" : "15px" }}>{props.title}</label>
      
      <div className={classes.barContainer}>
        <div className={classes.progressBar}>
          <div className={classes.progressFill} style={{width: (props.value / props.range * 100) + "%" }}></div>
          { props.showPercentage &&
            <div className={classes.percentage}>{Math.round(props.value * 100 / props.target)}%</div>
          }
          { props.showAmount &&
            <div className={classes.percentage}>€ {Math.round(props.value / 100) / 10}K</div>
          }
        </div>
        <div className={classes.markerLines}>
          {axisLines}
        </div>
        <div className={classes.markerLabels}>
          {markers}
        </div>
      </div>
    </div>
  )
}


export default bar
