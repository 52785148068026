import React from "react";

import NavigationHeader from "../../components/navigation/NavigationHeader/NavigationHeader";
import NavigationPanel from "../../components/navigation/NavigationPanel/NavigationPanel";
import Login from "../../components/authentication/Login/Login";
import classes from "./Layout.css";


const layout = (props) => {

  let content = null;

  if (props.isAuthenticated) {

    // Show the side panel and content
    content = (
      <>
        <NavigationPanel 
          enableAdministation={props.enableAdministation}
          enableSales={props.enableSales}
          enableStock={props.enableStock}
          enableDeployments={props.enableDeployments}
          enableInfrastructure={props.enableInfrastructure}/>
        <main className={classes.content}>
          {props.children}
        </main>
      </>
    )
  } else {

    // Show the login dialog
    content = (
      <Login {...props}/>
    );
  }

  return (
    <>
      <NavigationHeader
        isAuthenticated={props.isAuthenticated}
        username={props.username}
        logout={props.logout}/>
      {content}
    </>
  );
}

export default layout;
