import React, { Component } from "react";
import Modal from "../../../../containers/UI/Modal/Modal";
import InfoRow from "../../../configuration/Rows/InfoRow/InfoRow";
import ButtonRow from "../../../configuration/shared/ButtonRow/ButtonRow";
import classes from "./LicenseModal.css"
import axios from "../../../../axios"


class GatewayModal extends Component {

  state = {
    license: {},
  }

  componentDidMount() {
    this.setState({ license: this.props.license })
  }

  downloadHandler = () => {

    // Download file
    const fileName = this.state.license.gateway.serialNumber + ".pem"
    const blob = new Blob([this.state.license.licenseText], { type:"application/text" })
    const href = URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.href = href
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  deleteHandler = () => {

    if (window.confirm("CAREFUL! Are you sure you would like to delete this license?") === true) {

      // Request deletion
      axios.get("/license/delete/" + this.props.license._id)

      // Close the modal
      this.props.closeModal()
    }
  }


  render() {

    return (
      <Modal shouldShow={this.props.shouldShow}>
        <h3>{(this.state.license.gateway) ? this.state.license.gateway.trivialName + " license" : ""}</h3>

        <InfoRow
          title="Serial number"
          firstRow
          body={(this.state.license.gateway) ? this.state.license.gateway.serialNumber : ""}/>
        <InfoRow
          title="Validity"
          body={(this.state.license) ? this.props.formatDate(this.state.license.startDate) + " - " + this.props.formatDate(this.state.license.endDate) : ""}/>
       <InfoRow
          title="License format"
          body={(this.state.license) ? ((this.state.license.useNewFormat === true) ? "1.3 and later" : "1.2 and earlier") : ""}/>
        <InfoRow
          title="License type"
          body={(this.state.license) ? ((this.state.license.isForwarderSlave === true) ? "Forwarder gateway" : "Master gateway") : ""}/>
        {((this.state.license) ? !(this.state.license.isForwarderSlave === true) : false) &&
        <InfoRow
          title="External forwarders"
          body={(this.state.license.forwarderCount) ? (this.state.license.forwarderCount + " forwarder" + ((this.state.license.forwarderCount !== 1) ? "s" : "") + " allowed") : "None allowed"}/>}
        <InfoRow
          title="Internal radio"
          body={("enableInternalRadio" in this.state.license) ? (this.state.license.enableInternalRadio === true ? "Enabled" : "Disabled") : "Not yet enforced"}/>
        <InfoRow
          title="LoRa device limit"
          body={(this.state.license.loraDeviceLimit) ? (this.state.license.loraDeviceLimit + " devices") : "Not enforced"}/>
        <InfoRow
          title="Protocol conversion"
          body={!(this.state.license.protocols === undefined || this.state.license.protocols.length === 0) ? (this.state.license.protocols.join(", ")) : "No protocols allowed"}/>
        {this.state.license.whiteLabelVendor &&
        <InfoRow
          title="White-label vendor"
          body={this.state.license.whiteLabelVendor}/>
        }
        <ButtonRow
          title="License file"
          ultrawide
          body="Download .pem file"
          onClick={this.downloadHandler}/>

          <textarea
            className={classes.licenseField}
            value={this.state.license.licenseText}
            type="text"
            placeholder="License text"></textarea>


        <div className={classes.buttonRow}>
          <button className={classes.deleteButton} onClick={this.deleteHandler}>Delete</button>
          <button className={classes.closeButton} onClick={this.props.closeModal}>Close</button>
        </div>
      </Modal>
    );
  }
}

export default GatewayModal;
