import React, { Component } from "react";
import Modal from "../../../../containers/UI/Modal/Modal";
import InputRow from "../../../configuration/Rows/InputRow/InputRow";
import SelectRow from "../../../configuration/Rows/SelectRow/SelectRow";
import FileRow from "../../../configuration/shared/FileRow/FileRow";
import ButtonRow from "../../../configuration/shared/ButtonRow/ButtonRow";
import CheckboxRow from "../../../configuration/shared/CheckboxRow/CheckboxRow";
import classes from "./OrderModal.css"
import axios from "../../../../axios";



class OrderModal extends Component {

  state = {
    order: {},
    status: { "Order fulfilled": false },
    uploadFile: null,
    valuesChanged: false,
  }

  typeOptions = [
    { value: "product", label: "Product sale" },
    { value: "project", label: "Project work" },
  ]



  componentDidMount() {

    let order = this.props.selected

    // Create an order if necessary
    if (order === null) {

      // Propose a reference
      let today = new Date()

      order = {
        date: today.getTime() / 1000,
        internalReference: "ORD-" + today.getFullYear().toString().slice(-2) + String(today.getMonth() + 1).padStart(2, '0') + String(today.getDate()).padStart(2, '0') + "01",
        fulfilled: false,
        invoiced: false,
        pdfAvailable: false,
      }
    }
    

    // Parse date
    if (order.date) {
      order.humanDate = new Date(order.date * 1000).toLocaleDateString("en-GB")
    }

    this.setState({ 
      order: {...order},
      status: { "Order fulfilled": order.fulfilled },
      valuesChanged: (this.props.selected === null)
    })
  }


  // CRUD handlers

  saveOrderHandler = () => {

    if (this.state.valuesChanged) {

      let order = this.state.order

      // Convert date back to epoch format
      var parts = order.humanDate ? order.humanDate.split("/") : []
      if (parts.length === 3) {
        var date = new Date(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10));
        order.date = date.getTime() / 1000
      }

      // Parse order status into order
      order.fulfilled = this.state.status["Order fulfilled"]
      if (typeof order.totalAmount === "string") {
        order.totalAmount = order.totalAmount.replace(/,/g, '.')
      }
      if (typeof order.cogsAmount === "string") {
        order.cogsAmount = order.cogsAmount.replace(/,/g, '.')
      }

      // Validate form for errors
      if (this.validateOrder(order)) {

        // Sync to the backend
        axios.post("/orders/update", order)
        // Close the modal view
        this.props.closeModal()
      }
    } else {

      // Close the modal view instantly
      this.props.closeModal()
    }
  }

  validateOrder = (order) => {

    if (!order.customer) {
      alert("Please select a valid customer.")
      return false
    }
    if (!order.humanDate) {
      alert("Please enter an order date.")
      return false
    }
    if (!order.internalReference) {
      alert("Please enter an internal reference.")
      return false
    }
    if (!order.totalAmount) {
      alert("Please enter a total amount.")
      return false
    }
    if (!order.cogsAmount) {
      alert("Please enter a cost.")
      return false
    }
    if (isNaN(order.totalAmount)) {
      alert("Please enter a valid number for the total amount.")
      return false
    }
    if (isNaN(order.cogsAmount)) {
      alert("Please enter a valid number for the cost.")
      return false
    }
    if (!order.category) {
      alert("Please select a category.")
      return false
    }

    return true
  }

  deleteHandler = () => {

    // Sync to the backend
    axios.delete("/orders/delete/" + this.state.order._id)

    // Close the modal view
    this.props.closeModal()
  }


  // PDF handlers

  uploadPDF = (event, thisRef) => {

    // Read the file's contents
    var reader = new FileReader();
    reader.addEventListener("load", function() {

      // Save uploaded PDF
      let pdfContent = reader.result.split("base64,")[1]
      thisRef.updateProperty("pdfContent", pdfContent)
      thisRef.updateProperty("pdfAvailable", true)
    })
    reader.readAsDataURL(event.target.files[0])
  }

  downloadPDFhandler = () => {

    // Request the PDF from the backend first (lazy fetch)
    axios.get("/orders/pdf/" + this.state.order._id).then(response => {
      if (response.data) {

        if (response.data.pdfContent) {
          // Download PDF file
          const fileName = this.state.order.internalReference + ".pdf"
          const href = "data:application/pdf;base64," + response.data.pdfContent
          const link = document.createElement("a")
          link.href = href
          link.download = fileName
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          return 
        } 
      }
      alert("No PDF file was attached to this order entry.")

    }).catch((err) => {
      alert("Could not download order: " + err.message)
    })
  }


  // Edit handlers
  
  updateProperty(property, value) {
    let order = {...this.state.order}
    order[property] = value
    this.setState({ order: order, valuesChanged: true })
  }


  render() {

    let createMode = (this.props.selected === null)

    return (
      <Modal shouldShow={this.props.shouldShow}>
        <h2>{createMode ? "Upload new order" : "Edit order"}</h2>
        
        <SelectRow
          title="Customer"
          firstRow
          ultrawide
          value={this.state.order.customer ? (this.state.order.customer._id ?? "") : ""}
          onChange={(newValue) => this.updateProperty("customer", { _id: newValue.value })}
          options={this.props.customersOptions}/>
        <InputRow
          title="Order date"
          enabled
          ultrawide
          value={this.state.order.humanDate ?? ""}
          placeholder={"E.g. 15/01/2024"}
          onChange={(event) => this.updateProperty("humanDate", event.target.value)}/>
        <InputRow
          title="Internal reference"
          enabled
          ultrawide
          value={this.state.order.internalReference ?? ""}
          placeholder={"E.g. ORD-2024112301"}
          onChange={(event) => this.updateProperty("internalReference", event.target.value)}/>
        <InputRow
          title="Customer reference"
          enabled
          ultrawide
          value={this.state.order.customerReference ?? ""}
          placeholder={"E.g. PO/2024/2285"}
          onChange={(event) => this.updateProperty("customerReference", event.target.value)}/>
        <InputRow
          title="Total amount"
          enabled
          ultrawide
          value={this.state.order.totalAmount ?? ""}
          placeholder={"Amount excluding VAT"}
          onChange={(event) => this.updateProperty("totalAmount", event.target.value)}/>
        <InputRow
          title="Cost of goods"
          enabled
          ultrawide
          value={this.state.order.cogsAmount ?? ""}
          placeholder={"Direct cost"}
          onChange={(event) => this.updateProperty("cogsAmount", event.target.value)}/>
        <SelectRow
          title="Category"
          ultrawide
          value={this.state.order.category ? (this.state.order.category) : null}
          onChange={(newValue) => this.updateProperty("category", newValue.value )}
          options={this.typeOptions}/>
        <CheckboxRow
          title="Status"
          options={this.state.status}
          optionChanged={(option, checked) => {
            let status = {...this.state.status}
            status[option] = checked
            this.setState({ status: status, valuesChanged: true })
          }}/>

        { this.state.order.pdfAvailable &&
        <ButtonRow
          title="Download order PDF"
          ultrawide
          body="Download .pdf file"
          onClick={this.downloadPDFhandler}/>}

        <FileRow
          title={(this.state.order.pdfAvailable ? "Replace" : "Upload") + " order PDF"}
          body={"Choose .pdf file"}
          onClick={(pdf) => this.uploadPDF(pdf, this)}
          extension=".pdf"/>
          
        <div className={classes.buttonRow}>
          <button className={classes.deleteButton} onClick={this.deleteHandler}>Delete</button>
          <button className={(this.state.valuesChanged || createMode) ? classes.saveButton : classes.closeButton} onClick={this.saveOrderHandler}>{createMode ? "Upload" : this.state.valuesChanged ? "Save" : "Close"}</button>
        </div>
      </Modal>
    );
  }
}

export default OrderModal;
