import * as actionTypes from "../actions/actionTypes";

// MARK: - State structure
const initialState = {
  hasAdministrationPrivilege: false,
  hasSalesPrivilege: false,
  hasStockPrivilege: false,
  hasDeploymentPrivilege: false,
  hasInfrastructurePrivilege: false,
}


// MARK: - Reducer
const privilegeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PRIVILEGE_CHANGE: 
      return { 
        hasAdministrationPrivilege: action.hasAdministrationPrivilege,
        hasSalesPrivilege: action.hasSalesPrivilege,
        hasStockPrivilege: action.hasStockPrivilege,
        hasDeploymentPrivilege: action.hasDeploymentPrivilege,
        hasInfrastructurePrivilege: action.hasInfrastructurePrivilege 
      }
    default: return state;
  }
}

export default privilegeReducer
